<template>
  <contact-form />
</template>

<script>
import ContactForm from "../components/ContactForm.vue";
export default {
  name: "ContactView",
  components: { ContactForm },
};
</script>

<template>
  <div class="contacting-genesis-container">
    <div class="contacting-genesis-header">
      <h5><strong>Contacting Genesis</strong></h5>
    </div>
    <div class="container">
      <div class="row">
        <div class="col contacting-options-container">
          <a class="contacting-options-container-links" href="/contact"
            ><img src="../assets/images/message.png" height="25" />&nbsp;
            <p>Send Message</p></a
          >
          <a class="contacting-options-container-links" href="#">
            <img src="../assets/images/add-friend.png" height="25" />&nbsp;
            <p>Add to Friends</p>
          </a>
          <a class="contacting-options-container-links" href="">
            <img src="../assets/images/chat.png" height="25" />&nbsp;
            <p>Instant Message</p>
          </a>
          <a class="contacting-options-container-links" href="">
            <img src="../assets/images/add-to-group.png" height="25" />&nbsp;
            <p>Add to Group</p>
          </a>
        </div>
        <div class="col contacting-options-container">
          <a class="contacting-options-container-links" href="#">
            <img
              src="../assets/images/forward-to-friend.png"
              height="25"
            />&nbsp;
            <p>Forward to a Friend</p>
          </a>
          <a class="contacting-options-container-links" href="#">
            <img
              src="../assets/images/add-to-favorites.png"
              height="25"
            />&nbsp;
            <p>Add to Favorites</p>
          </a>
          <a class="contacting-options-container-links" href="#">
            <img src="../assets/images/block-user.png" height="25" />&nbsp;
            <p>Block user</p>
          </a>
          <a class="contacting-options-container-links" href="#">
            <img src="../assets/images/rank-user.png" height="25" />&nbsp;
            <p>Rank user</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
